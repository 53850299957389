<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tpm.routine') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                     <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                      <b-form-input
                        id="circular_memo_no"
                        v-model.lazy="search.circular_memo_no"
                        :placeholder="$t('elearning_iabm.circular_memo_no')"
                      ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="venue_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_tpm.vanue')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.venue_id"
                            :options="venus"
                            id="venue_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card v-if='datas.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('elearning_tpm.routine') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button variant="primary" @click="pdfExport" class="mr-2">
                            {{ $t('globalTrans.export_pdf') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                    <b-row>
                                        <b-col>
                                            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                                <template v-slot:projectNameSlot>
                                                {{ }}
                                                </template>
                                                {{ $t('elearning_tpm.routine') }}
                                            </list-report-head>
                                        </b-col>
                                    </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;">
                                          <tr v-if="search.circular_memo_no">
                                            <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                          </tr>
                                          <tr v-if="search.date">
                                            <td align="right" style="width:45%">{{ $t('globalTrans.date') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ search.date | dateFormat }}</td>
                                          </tr>
                                          <tr v-if="search.fiscal_year">
                                            <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                          </tr>
                                          <tr v-if="search.org && ( $store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12)">
                                            <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_bn : search.org }}</td>
                                          </tr>
                                          <tr v-if="search.training_type">
                                            <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                          </tr>
                                          <tr v-if="search.training_category">
                                            <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                          </tr>
                                          <tr v-if="search.training_title">
                                            <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                          </tr>
                                          <tr v-if="search.vanue">
                                            <td align="right" style="width:45%">{{ $t('elearning_tpm.vanue') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.vanue_bn : search.vanue }}</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('globalTrans.date') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('globalTrans.time') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('globalTrans.course') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('elearning_tpm.course_module') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('elearning_tpm.trainer') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                          <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ info.training_date | dateFormat }}</b-td>
                                          <b-td class="text-center">{{ info.start_time | time12FormateTraining }} - {{ info.end_time | time12FormateTraining }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.course_name_bn : info.course_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.course_module_name_bn : info.course_module_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.trainer_name_bn : info.trainer_name }}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
          <!-- <pre>{{warehouseServiceBaseUrl}}</pre>
          <pre>{{search}}</pre> -->
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingScheduleRoutine, personalDataList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import routinePdf from './routine_pdf'
export default {
    mixins: [ModalBaseMasterList],
     components: {
      ListReportHead
    },
    data () {
        return {
          search: {
            circular_memo_no: '',
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            venue_id: 0,
            fiscal_year_id: 0
          },
          trainingCategoryList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          showData: true,
          trainingTitleList: [],
          personalInfoList: []
        }
    },
    computed: {
      venus: function () {
        return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('elearning_config.update')
      },
      columns () {
          const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('elearning_config.organization'), class: 'text-left' },
            { label: this.$t('elearning_config.training_title'), class: 'text-left' },
            { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
            { label: this.$t('globalTrans.course'), class: 'text-left' },
            { label: this.$t('elearning_tpm.course_module'), class: 'text-left' },
            { label: this.$t('elearning_tpm.trainer'), class: 'text-left' },
            { label: this.$t('globalTrans.date'), class: 'text-left' },
            { label: this.$t('globalTrans.start_time'), class: 'text-left' },
            { label: this.$t('globalTrans.end_time'), class: 'text-left' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              { key: 'circular_memo_no' },
              { key: 'course_name_bn' },
              { key: 'course_module_name_bn' },
              { key: 'trainer_name_bn' },
              { key: 'training_date' },
              { key: 'start_time' },
              { key: 'end_time' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'org' },
              { key: 'course_name' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'course_module_name' },
              { key: 'trainer_name' },
              { key: 'training_date' },
              { key: 'start_time' },
              { key: 'end_time' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.personalDataList()
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        // this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async personalDataList () {
          const params = {
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalDataList, params)
          if (result.success) {
              this.personalInfoList = result.data
          } else {
              this.personalInfoList = []
          }
          this.searchData()
      },
      async searchData () {
        this.loadData()
      },
      loadData () {
        this.getCustomDataSearch()
        const circularList = {
          circular_memo_no: this.search.circular_memo_no
        }
        RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleRoutine, circularList).then(response => {
          if (response.success) {
            this.datas = this.getCustomDataList(response.data)
          }
        })
      },
      getCustomDataSearch () {
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(organizationProfileList => organizationProfileList.value === parseInt(this.search.org_id))
        if (typeof orgObj !== 'undefined') {
          this.search.org = orgObj.text_en
          this.search.org_bn = orgObj.text_bn
        } else {
          this.search.org = ''
          this.search.org_bn = ''
        }
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.search.fiscal_year = fiscalYearObj.text_en
          this.search.fiscal_year_bn = fiscalYearObj.text_bn
        //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.search.fiscal_year = ''
          this.search.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.search.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
          this.search.training_title = trainingTitleObj.text_en
          this.search.training_title_bn = trainingTitleObj.text_bn
        } else {
          this.search.training_title = ''
          this.search.training_title_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.search.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
          this.search.training_type = trainingTypeObj.text_en
          this.search.training_type_bn = trainingTypeObj.text_bn
        } else {
          this.search.training_type = ''
          this.search.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.search.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
          this.search.training_category = trainingCategoryObj.text_en
          this.search.training_category_bn = trainingCategoryObj.text_bn
        } else {
          this.search.training_category = ''
          this.search.training_category_bn = ''
        }
        const vanueObj = this.$store.state.TrainingElearning.commonObj.venus.find(vanue => vanue.value === parseInt(this.search.venue_id))
        if (typeof vanueObj !== 'undefined') {
          this.search.vanue = vanueObj.text_en
          this.search.vanue_bn = vanueObj.text_bn
        } else {
          this.search.vanue = ''
          this.search.vanue_bn = ''
        }
        const trainerObj = this.personalInfoList.find(personalInfoList => personalInfoList.value === parseInt(this.search.trainer_id))
        if (typeof trainerObj !== 'undefined') {
          this.search.trainer_name = trainerObj.text_en
          this.search.trainer_name_bn = trainerObj.text_bn
        } else {
          this.search.trainer_name = ''
          this.search.trainer_name_bn = ''
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(item.course_id))
          const courseData = {}
          if (typeof courseObj !== 'undefined') {
            courseData.course_name = courseObj.text_en
            courseData.course_name_bn = courseObj.text_bn
          } else {
            courseData.course_name = ''
            courseData.course_name_bn = ''
          }
          const courseModuleObj = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(doc => doc.value === parseInt(item.course_module_id))
          const courseModuleData = {}
          if (typeof courseModuleObj !== 'undefined') {
            courseModuleData.course_module_name = courseModuleObj.text_en
            courseModuleData.course_module_name_bn = courseModuleObj.text_bn
          } else {
            courseModuleData.courseModule_name = ''
            courseModuleData.courseModule_name_bn = ''
          }
          const trainerObj = this.personalInfoList.find(doc => doc.value === parseInt(item.trainer_id))
          const trainerData = {}
          if (typeof trainerObj !== 'undefined') {
            trainerData.trainer_name = trainerObj.text_en
            trainerData.trainer_name_bn = trainerObj.text_bn
          } else {
            trainerData.trainer_name = ''
            trainerData.trainer_name_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, courseData, courseModuleData, trainerData)
        })
        return listData
      },
      pdfExport () {
        const reportTitle = this.$t('elearning_tpm.routine')
        routinePdf.routinePdf(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
      }
    }
}
</script>
